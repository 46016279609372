import './navbar.css';
import { useEffect, useState } from 'react';
import logoMega from '../../assets/LogoM.png'
import {AiOutlineMenu} from 'react-icons/ai';
import {Link} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { IoIosArrowDown } from 'react-icons/io';

const scrollToTop = () => {
    window.scrollTo(0, 0);
}

const Navbar = ({home, sobre, solucoes, segmentos}) => {

    const Menu = () => (
        <>  
            <p><Link onClick={scrollToTop} to='/'><a href="#h" id={home}>Home</a></Link></p>
            <p><Link onClick={scrollToTop} to='/sobrenos'><a href="#s" id={sobre}>Sobre nós</a></Link></p>
            {/* <p><Link to='/solucoes/cloudcall'><a href="#solucoes">Soluções</a></p> */}
            <div className="dropdown">
                <button className="dropbtn">
                    <p id={solucoes}>Soluções</p>
                    <IoIosArrowDown className='arrowIcon' id={solucoes}/>
                </button>
                <div className="dropdown-content">
                    <Link onClick={scrollToTop} to='/solucoes/cloudcall'><a href="#cloudcall">Cloud Call</a></Link>
                    <Link onClick={scrollToTop} to='/solucoes/cloudchat'><a href="#cloudchat">Cloud Chat</a></Link>
                    <Link onClick={scrollToTop} to='/solucoes/urabot'><a href="#urabot">Ura-Bot</a></Link>
                    <Link onClick={scrollToTop} to='/solucoes/sms'><a href="#sms">SMS</a></Link>
                    <Link onClick={scrollToTop} to='/solucoes/telefoniavoip'><a href="#telefoniavoip">Telefonia</a></Link>
                    <Link onClick={scrollToTop} to='/solucoes/pabxip'><a href="#pabxip">PABX IP</a></Link>
                </div>
            </div>
            <div className="dropdown">
                <button className="dropbtn">
                    <p id={segmentos}>Segmentos</p>
                    <IoIosArrowDown className='arrowIcon' id={segmentos}/>
                </button>
                <div className="dropdown-content">
                    <Link onClick={scrollToTop} to='/segmentos/venda'><a href="#cloudcall">Vendas</a></Link>
                    <Link onClick={scrollToTop} to='/segmentos/sac'><a href="#cloudchat">SAC</a></Link>
                    <Link onClick={scrollToTop} to='/segmentos/atendimento'><a href="#urabot">Atendimento</a></Link>
                    <Link onClick={scrollToTop} to='/segmentos/cobranca'><a href="#sms">Cobrança</a></Link>
                </div>
            </div>
            <p><HashLink to="#contratar">Contratar</HashLink></p>
        </>
    )

    const [menu, setMenu] = useState(false);
    const [scrolled, setScrolled] = useState(false);
  

    const handleClose = () => {
        setMenu(false);
    }

    const handleOpen = () => {
        setMenu(true);
    }

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 10){
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        }

        window.addEventListener("scroll", onScroll);

        return () => window.addEventListener("scroll", onScroll);

    }, [])


  return (
    <div  className={scrolled ? 'navbar navbar-bg' : 'navbar '}>
        <div className='navbar-content section__padding'>
            <div className='navbar-links_logo'>
                    <Link onClick={scrollToTop} to="/"><img src={logoMega} alt='Mega Conecta logo'/></Link>
                </div>
            <div className={!menu ? 'navbar-links' : 'navbar-links_container-mobile'}>
                <div className='navbar-links_container'>
                        <Menu />
                </div>
            </div>  
            <div className='navbar-menu-icon'>
                <AiOutlineMenu className={!menu ? 'icon-navbar' : 'none'} onClick={handleOpen}/>
                <AiOutlineMenu className={menu ? 'icon-navbar-close' : 'none'} onClick={handleClose}/>
            </div> 
        </div>
    </div>
  )
}

export default Navbar
