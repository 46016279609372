import React from 'react'
import './beneficiosCall.css'

import imagem from '../../../../assets/beneficiosCall.png'

const BeneficiosCall = () => {
  return (
    <div className='container-beneficiosCall'>
        <div className='beneficiosCall-content section__padding'>
            <h1 data-aos="fade-left">BENEFICIOS</h1>
            <div className='content__sections'>
                <div className='content__section-left'>
                    <ol>
                        <li data-aos="fade-right">
                            <h2>REDUÇÃO DE CUSTOS</h2>
                            <p>Com o Cloud Call você reduz os custos e
                            aumenta os lucros da sua empresa. Isso
                            gera economia inteligente e multiplica os
                            resultados do seu negócio.</p>
                        </li>
                        <li data-aos="fade-right" data-aos-delay="200">
                            <h2>MAIOR PRODUTIVIDADE</h2>
                            <p>O Cloud Call facilita sua gestão, otimiza os
                                processos da sua operação e entrega
                                ligações assertivas para multiplicar suas
                                vendas!
                            </p>
                        </li>
                        <li data-aos="fade-right" data-aos-delay="400">
                            <h2>AUTONOMIA</h2>
                            <p>O Cloud Call é um sistema que lhe permite
                                criar e personalizar tudo o que você precisa
                                para ter uma gestão completa.
                            </p>
                        </li>
                    </ol>
                </div>
                <div className='content__section-right' data-aos="fade-up">
                    <img src={imagem} alt='Beneficios com a Mega Conecta'/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default BeneficiosCall
