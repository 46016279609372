import React from 'react'
import './sobremap.css'
import imagem1 from '../../../assets/sobreMapWhats.png'
import imagem2 from '../../../assets/sobreMapLocation.png'
import imagem3 from '../../../assets/sobreMapEmail.png'

const SobreMaps = () => {
  return (
    <div className='container-sobremaps'>
        <div className='sobremaps-content section__padding'>
            <div className='sobremaps-content__cards'>
                <div className='card-whats' data-aos="fade-right">
                    <img src={imagem1} alt='Whatsapp Mega Conecta'/>
                    <a href="tel:1141184100"><p>(11) 4118-4100</p></a>
                </div>
                <div className='card-location' data-aos="fade-down">
                    <img src={imagem2} alt='Localização Mega Conecta'/>
                    <p>AV. Vereador Abel Ferreira N° 1844 - Sala 315 Jardim Anália Franco - SP CEP: 033840-000</p>
                </div>
                <div className='card-email' data-aos="fade-left">
                    <img src={imagem3} alt='Email Mega Conecta'/>
                    <a href="mailto:contato@megaconecta.com.br"><p>contato@megaconecta.com.br</p></a>
                </div>
            </div>            
            <div className='sobremaps-content__maps'>
                <iframe className='maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.125089495365!2d-46.56486438886164!3d-23.5639504615994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5e9bebc2b3e9%3A0x7ff8308a30d5e4d8!2sMega%20Conecta!5e0!3m2!1spt-BR!2sbr!4v1685299584361!5m2!1spt-BR!2sbr" style={{border: '0', display: 'flex', width: '100%', height: "550px", borderRadius: "28px", zIndex: '0'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="myFrame"/>
            </div>
        </div>
    </div>
  )
}

export default SobreMaps
