import React from 'react'
import './beneficiosComponent.css'

const BeneficiosComponent = ({tituloLista, conteudoLista, key}) => {
  return (
        <li>
            <h2 className="numbered">{tituloLista}</h2>
            <p>{conteudoLista}</p>
        </li>
  )
}

export default BeneficiosComponent
