import React, { useEffect, useState } from 'react'
import emailjs from '@emailjs/browser';
import './contato.css'
import { useCookies } from "react-cookie";
import crypto from "crypto-js"

const Contato = () => {

  const [name, setName] = useState('');  
  const [email, setEmail] = useState('');  
  const [whatsapp, setWhatsapp] = useState('');  
  const [empresa, setEmpresa] = useState('');  
  const [check, setCheck] = useState(false); 
  const [width, setWidth] = useState(window.innerWidth);
  const [device, setDevice] = useState('Desktop');
  const [buttonText, setButtonText] = useState('Enviar');

  const [cookies, setCookie] = useCookies(["formulario", "cookies"]);

  const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
  }

  useEffect(() => {
          window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  useEffect(() => {
    if(width <= 768){
        setDevice('Mobile')
    }
  }, [width]);

  const sendEmail = async (e) => {
    e.preventDefault();
    setButtonText('Enviando...');
        
    // var encrypted = crypto.AES.encrypt(password, "Secret Passphrase");
    // var decrypted = crypto.AES.decrypt(encrypted, "oirtasuvx");

    // console.log(decrypted.toString(crypto.enc.Utf8))
    if(cookies.cookies){
       
        const nameHash = crypto.AES.encrypt(name, "oirtasuvx");
        const empresaHash = crypto.AES.encrypt(empresa, "oirtasuvx");
        const emailHash = crypto.AES.encrypt(email, "oirtasuvx");
        const whatsappHash = crypto.AES.encrypt(whatsapp, "oirtasuvx");

        setCookie(crypto.AES.encrypt("Name", "oirtasuvx"), nameHash.toString(), { path: '/' });
        setCookie(crypto.AES.encrypt("Empresa", "oirtasuvx"), empresaHash.toString(), { path: '/' });
        setCookie(crypto.AES.encrypt("Email", "oirtasuvx"), emailHash.toString(), { path: '/' });
        setCookie(crypto.AES.encrypt("Whatsapp", "oirtasuvx"), whatsappHash.toString(), { path: '/' });

        // setCookie("formulario", nameHash, { path: '/' });
        // setCookie("formulario", empresaHash, { path: '/' });
        // setCookie("formulario", emailHash, { path: '/' });
        // setCookie("formulario", whatsappHash, { path: '/' });
    }

    const templateParams = {
        from_name: name,
        email: email,
        whatsapp: whatsapp,
        empresa: empresa, 
        check: check ? 'Aceito receber comunicações' : '',
        device: device
    }
   
    if(name === '' || email === '' || whatsapp === '' || empresa === ''){
        alert('Preencha todos os campos');
        return;
    }

    setButtonText("Enviado!");
    emailjs.send("service_zqltllw", "template_c89a6q4", templateParams,"nUHyJWaIW-PRvQrqj")
    .then((response) => {
        console.log("EMAIL ENVIADO", response.status, response.text)
        setName('')
        setEmail('')
        setWhatsapp('')
        setEmpresa('')
        setCheck(false)
    }, (err) => {
        console.log('ERRO: ', err)
    })
  }

  return (
    <section className='container-contato' id='contratar'>
        <div className='contato-content section__padding'>
            <div className='contato-content__left'>
                <div className='contato-content__title'>  
                    <h1 data-aos="fade-right" data-aos-delay="100">Gostou das
                        nossas
                        soluções mas
                        ainda está
                        com dúvidas?</h1>
                    <div className='contato-content__paragrafo'>
                        <p data-aos="fade-up" >
                        Preencha o formulário ao lado que
                        entraremos em contato o mais
                        rápido possível.  
                        </p>
                    </div>
                </div>
            </div>
            <form onSubmit={sendEmail}>
                <div className='contato-content__right'>
                    <div className='contato-right__inputs' data-aos="fade-left" data-aos-delay="200">
                        <input 
                            className='input-right' 
                            type="text" 
                            placeholder='Nome'
                            onChange={(e) => setName(e.target.value)}
                            value={name}/>
                        <input 
                            className='input-right' 
                            type="text" 
                            placeholder='E-mail'
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}/>
                        <input className='input-right' 
                            type="text" 
                            placeholder='Whatsapp'
                            onChange={(e) => setWhatsapp(e.target.value)}
                            value={whatsapp}/>
                        <input className='input-right' 
                            type="text" 
                            placeholder='Empresa'
                            onChange={(e) => setEmpresa(e.target.value)}
                            value={empresa}/>
                    </div>
                    <div className='right-chekbox'>
                        <label className="container" data-aos="fade-right" data-aos-delay="200">
                            <p >Eu concordo em receber comunicações</p>
                            <input type="checkbox" onClick={() => setCheck(true)}/>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <button type='submit' data-aos="fade-up">{buttonText}</button>
                </div>
            </form>
        </div>
    </section>
  )
}

export default Contato
