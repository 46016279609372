import React from 'react'
import DuvidaComponent from '../../../../components/duvidaComponent/sms/DuvidaComponent'

const ConteTelefonia = () => {

  return (
    <div>
        <DuvidaComponent
            titulo={'CONTE COM:'}
        />
    </div>
  )
}

export default ConteTelefonia

