import React from 'react'
import HeaderComponent from '../../../../components/header/HeaderComponent'
import imagem from '../../../../assets/CloudcallImagem.png'

const dadosHeader = {
  titulo: 'CLOUD CALL',
  texto: 'O melhor Discador Automático do mercado!\n O Cloud Call é um sistema de discagem\n automática que vai potencializar sua\n operação, multiplicar o número de contatos\n assertivos e turbinar os resultados da sua\n empresa.',
  imagem: imagem
}

const HeaderCloudCall = () => {
  return (
    <div>
      <HeaderComponent
        {...dadosHeader}
      />
    </div>
  )
}

export default HeaderCloudCall
