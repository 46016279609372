import React from 'react'
import HeaderComponent from '../../../../components/header/HeaderComponent'
import imagem from '../../../../assets/imagem4.png'

const dadosHeader = {
  titulo: 'CLOUD CHAT',
  texto: 'Tenha toda o seu time comercial atendendo em apenas uma conta de WhatsApp e ainda gerencie toda sua base de clientes em um único lugar',
  imagem: imagem
}

const HeaderCloudChat = () => {
  return (
    <div>
      <HeaderComponent
        {...dadosHeader}
      />
    </div>
  )
}

export default HeaderCloudChat
