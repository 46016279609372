import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import HeaderCloudCall from '../../containers/solucoesContainer/cloudcall/header/HeaderCloudCall'
import Conte from '../../containers/solucoesContainer/cloudcall/conte/Conte'
import BeneficiosCall from '../../containers/solucoesContainer/cloudcall/beneficios/BeneficiosCall'
import Vantagens from '../../containers/solucoesContainer/cloudcall/vantagens/Vantagens'
import Conheca from '../../containers/solucoesContainer/cloudcall/conheca/Conheca'
import Footer from '../../containers/homeContainer/footer/Footer'
import Revolucionar from '../../containers/solucoesContainer/cloudcall/revolicionar/Revolucionar'
import Whatsapp from '../../components/whatsapp/Whatsapp'

import { useInView } from "react-intersection-observer";

const Cloudcall = () => {

  const [ref, inView] = useInView({
    /* Optional options */
    triggerOnce: true,
    rootMargin: '0px 0px',
  })

  return (
    <div className='scroll' ref={ref}>
        <Navbar solucoes = {inView ? 'active' : ''}/>
        <HeaderCloudCall/>
        <Conte/>
        <BeneficiosCall/>
        <Vantagens/>
        <Conheca/>
        <Revolucionar/>
        <Footer/>
        <Whatsapp/>
    </div>
  )
}

export default Cloudcall
