import React, { useState, useEffect } from 'react'
import './servicoscard.css'
import { Link } from 'react-router-dom';

const ServicosCard = ({title, image, subtitle, text, link, css}) => {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    const [right, setRight] = useState(false);

    useEffect(() => {
        if(css !== "direito"){
            setRight(true);
        }
      }, [setRight, css]);
    
  return (
    <>
    {right ?    
    <div className='servicos-content_section'>
        <div className='section-left text' data-aos="fade-right">
            <div className='title-left'>
                <h1>{title}</h1>
            </div>
            <div className='subtitle-left'>
                <p id="section-subtitle">{subtitle}</p>
            </div>
            <p>{text}</p>
            <div className='link-left'>
                <Link onClick={scrollToTop} to={link}><h2>Saiba mais!</h2></Link>
            </div>
        </div>
        <div className='section-right justify' data-aos="fade-left">
            <div className='imagem-left'>
                <img src={image} alt="SolutionsImage"/>
            </div>
        </div>
    </div> : 
    <div className='servicos-content_section reverse'>
        <div className='section-left reverseText' data-aos="fade-left">
            <div className='title-right'>
                    <h1>{title}</h1>
                </div>
                <div className='subtitle-right'>
                    <p id="section-subtitle">{subtitle}</p>
                </div>
                <p>{text}</p>
                <div className='link-right'>
                    <Link onClick={scrollToTop} to={link}><h2>Saiba mais!</h2></Link>
                </div>
            </div>
        <div className='section-right reverseJustify' data-aos="fade-right">
            <div className='imagem-right'>
                <img src={image} alt="Imagens soluções"/>
            </div>
        </div>
    </div>
    }
    </>
  )
}

export default ServicosCard
