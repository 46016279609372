import React, { useEffect, useState } from 'react'
import './footer.css'
import logo from '../../../assets/LogoSolucoes.png'
import instagram from '../../../assets/instagram.png'
import facebook from '../../../assets/facebook.png'
import youtube from '../../../assets/youtube.png'
import whatsapp from '../../../assets/whatsapp.png'
import { Link } from 'react-router-dom'

const Footer = () => {

    const [width, setWidth] = useState(window.innerWidth);

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

  return (
    <div className='container-footer'>
        <div className='footer-content section__padding'>
                <div className='footer-content__logo'>
                    <img src={logo} alt='Mega Conecta Logo'/>
                </div>
                <div className='footer-content__middle'>
                    <div className='middle-home'>
                        <Link onClick={scrollToTop} to='/'><h2>Home</h2></Link>
                    </div>
                    <div className='middle-solucoes'>
                    <h2>Soluções</h2>
                        <div className='solucoes-links'>
                            <Link onClick={scrollToTop} to='/solucoes/cloudcall'><p>Cloud Call</p></Link>
                            <Link onClick={scrollToTop} to='/solucoes/cloudchat'><p>Cloud Chat</p></Link>
                            <Link onClick={scrollToTop} to='/solucoes/urabot'><p>Ura-bot</p></Link>
                            <Link onClick={scrollToTop} to='/solucoes/sms'><p>SMS</p></Link>
                            <Link onClick={scrollToTop} to='/solucoes/telefoniavoip'><p>Telefonia VoIP</p></Link>
                            <Link onClick={scrollToTop} to='/solucoes/pabxip'><p>PABX IP</p></Link>
                        </div>
                    </div>
                    <div className='middle-sobre'>
                        <Link onClick={scrollToTop} to='/sobrenos'><h2>Sobre nós</h2></Link>
                    </div>
                </div>
                <div className='footer-content-contact'>
                    <h2>Fale Conosco</h2>
                    <p><a href="tel:1141184100">(11) 4118-4100</a></p>
                    <p><a href="mailto:contato@megaconecta.com.br">contato@megaconecta.com.br</a></p>
                    <div className='contact-logos'>
                        <a href="https://www.instagram.com/megaconecta/" target="_blank" rel="noopener noreferrer"><img src={instagram} alt=''/></a>
                        <a href='https://www.facebook.com/profile.php?id=100084048563318' target="_blank" rel="noopener noreferrer"><img src={facebook} alt=''/></a>
                        <a href='https://www.youtube.com/@megaconecta848' target="_blank" rel="noopener noreferrer"><img src={youtube} alt=''/></a>
                        <a href={isMobile ? 'https://wa.me/551141184100' : 'https://web.whatsapp.com/send/?phone=551141184100&text&type=phone_number&app_absent=0'} target="_blank" rel="noopener noreferrer"><img src={whatsapp} alt=''/></a>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default Footer
