import React from 'react'
import './atendimentoComp.css'
import imagem from '../../../../assets/cobrancaAtendente.png'
import SegmentosComponent from '../../../../components/segmentosComponent/SegmentosComponent'

const segmento = {
    title: 'COBRANÇA',
    text: 'Aumente a eficiência e a performance da sua operação de cobrança com as soluções da Mega Conecta. Tenha mais negociações e oportunidades de fechamento com as melhores soluções do mercado.',
    image: imagem
}

const CobrancaComp = () => {
  return (
    <div className='container-vendasComp'>
        <div className='vendasComp-content section__padding'>
           <SegmentosComponent
            {...segmento}/>
        </div>
    </div>
  )
}

export default CobrancaComp
