import React from 'react'
import HeaderComponent from '../../../components/header/HeaderComponent'
import imagem from '../../../assets/sobreImagem9.png'


const dadosHeader = {
    titulo: 'Nossas conexões são o que nos tornam Mega',
    texto: 'Somos uma empresa movida pelo desejo de criar e desenvolvier soluções efetivas que ajudem nossos clientes a crescerem cada vez mais.',
    imagem: imagem
}

const HeaderSobre = () => {
  return (
    <div>
         <HeaderComponent 
        {...dadosHeader}/> 
    </div>
  )
}

export default HeaderSobre
