import React from 'react'
import './beneficiosCall.css'

import imagem from '../../../../assets/PabxImg.png'
import BeneficiosComponent from '../../../../components/beneficiosComponent/BeneficiosComponent'

const conteudoBeneficio = [
    {
        tituloLista: 'MOBILIDADE',
        conteudoLista: 'O PABX IP da Mega Conecta opera com a telefonia VoIP, ou seja, você pode configurar os ramais em vários dispositivos, de acordo com a sua necessidade.'
    },
    {
        tituloLista: 'ECONOMIA',
        conteudoLista: 'Contratar o PABX IP da Mega Conecta, significa investir em uma nova era comunicacional que trará ao seu negócio economia e desenvolvimento.'
    },
    {
        tituloLista: 'TECNOLOGIA',
        conteudoLista: 'A tecnologia é fundamental para o crescimento de qualquer negócio e, por isso, desenvolvemos os nossos serviços com a melhor tecnologia, entregando melhor desempenho e qualidade aos nossos clientes.'
    }
]

const BeneficiosPabx = () => {
  return (
    <div className='container-beneficiosCall'>
        <div className='beneficiosCall-content section__padding'>
            <h1>BENEFICIOS</h1>
            <div className='content__sections'>
                <div className='content__section-left'>
                    <ol>
                    {
                        conteudoBeneficio.map((conteudo, index) => (
                            <BeneficiosComponent
                                key={index}
                                {...conteudo}/>
                        ))
                    }
                    </ol>
                </div>
                <div className='content__section-right'>
                    <img src={imagem} alt=''/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default BeneficiosPabx
