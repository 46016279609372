import React from 'react'
import SumarioCard from '../../sumarioCard/SumarioCard'
import '../style/duvidaComponent.css'

const DuvidaComponent = () => {
    
    const sumCardRight = [
        {
            title: "Qualificador de chamadas",
            description: "O Qualificador identifica os números desqualificados da sua base, dessa forma, ele elimina automaticamente esses números. Assim, o sistema liga somente para os clientes que estão com números qualificados, com isso, você terá agilidade no atendimento, gerando mais rapidez e economia."
        },
        {
            title: "Integração  com SMS",
            description: "Aumente sua taxa de conversão! Com o SMS integrado a Ura-Bot, você potencializa as suas campanhas através de disparos automáticos de SMS para os clientes que não tiveram contato por ligação."
        },
        {
            title: "Telefonia Voip inclusa",
            description: "Com a Ura-Bot, você tem toda telefonia incluída no plano e ainda conta com a qualidade digital nas chamadas."
        },
        {
            title: "Ligações ilimitadas",
            description: "Com a Ura-Bot, você não precisa se preocupar com a quantidade de ligações. A Ura-Bot realiza chamadas sem limite!"
        },
        {
            title: "Áudio personalizado",
            description: "Abordagem certa, para o cliente certo! Com o áudio de interação personalizado, você tem maior assertividade e resultados positivos em suas campanhas"
        }
    ]

    const sumCardLeft = [
        {
            title: "Tela de gestão de LEADS",
            description: "Gerencie os LEADS captados através de um painel fácil e interativo, ou se preferir, inicie uma conversa pelo WhatsApp com apenas um clique."
        },
        {
            title: "Integração com os melhores CRMs",
            description: "A Ura-Bot possui API de integração para você utilizar o sistema junto ao seu CRM."
        },
        {
            title: "Gravação das chamadas",
            description: "Com a Ura-Bot, você tem um servidor dedicado para armazenar todas as chamadas, garantindo a segurança e confiabilidade das ligações."
        },
        {
            title: "Servidor em nuvem",
            description: "Esqueça os fios e aparelhagens! A Ura-Bot é um sistema de alta tecnologia, com toda infraestrutura em nuvem, que permite uma gestão facilitada de qualquer lugar."
        },
        {
            title: "Treinamento e suporte",
            description: "Com a Mega Conecta, você tem apoio do time de suporte técnico e da área de relacionamento para facilitar o uso do sistema e performar ainda mais."
        }
    ]

  return (
    <div className='container-duvidas'>
        <div className='duvidas-content section__padding'>
            <div className='duvidas-content__title-component'>
                <h1 data-aos="fade-left">CONTE COM:</h1>
            </div>
            <div className='duvidas-content__sumario'>
                <div className='sumario-left' data-aos="fade-right">
                    {
                        sumCardLeft.map((sumario, index) => (
                            <SumarioCard
                                key={index}
                                {...sumario}
                            />
                        ))
                    }
                </div>
                <div className='sumario-right' data-aos="fade-left">
                {
                        sumCardRight.map((sumario, index) => (
                            <SumarioCard
                                key={index}
                                {...sumario}
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default DuvidaComponent
