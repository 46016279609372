import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import HeaderPabx from '../../containers/solucoesContainer/pabxip/header/HeaderPabx'
import ContePabx from '../../containers/solucoesContainer/pabxip/conte/ContePabx'
import BeneficiosPabx from '../../containers/solucoesContainer/pabxip/beneficios/BeneficiosPabx'
import Vantagens from '../../containers/solucoesContainer/cloudcall/vantagens/Vantagens'
import Conheca from '../../containers/solucoesContainer/cloudcall/conheca/Conheca'
import Revolucionar from '../../containers/solucoesContainer/cloudcall/revolicionar/Revolucionar'
import Footer from '../../containers/homeContainer/footer/Footer'
import Whatsapp from '../../components/whatsapp/Whatsapp'

import { useInView } from "react-intersection-observer";

const PabxIp = () => {

  const [ref, inView] = useInView({
    /* Optional options */
    triggerOnce: true,
    rootMargin: '0px 0px',
  })

  return (
    <div className='scroll' ref={ref}>
      <Navbar solucoes = {inView ? 'active' : ''}/>
      <HeaderPabx/>
      <ContePabx/>
      <BeneficiosPabx/>
      <Vantagens/>
      <Conheca/>
      <Revolucionar/>
      <Footer/>
      <Whatsapp/>
    </div>
  )
}

export default PabxIp
