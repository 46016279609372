import React from 'react'
import SumarioCard from '../../sumarioCard/SumarioCard'
import '../style/duvidaComponent.css'

const DuvidaComponent = () => {
    
    const sumCardRight = [
        {
            title: "Mapeamento de envios",
            description: "Tenha acesso a toda jornada do funil! Do envio da mensagem até a resposta do cliente, você pode acompanhar tudo de maneira fácil, simples e em tempo real."
        },
        {
            title: "Dashboard completa",
            description: "Com a Dashboard, você consegue visualizar os relatórios mais importantes, indicadores e métricas para performar e aumentar a taxa de conversão."
        },
        {
            title: "Integração com os melhores CRMs",
            description: "O SMS da Mega Conecta possui API de integração para você utilizar o sistema junto ao seu CRM"
        },
        {
            title: "Treinamento e suporte completo",
            description: "Com a Mega Conecta, você tem apoio do time de suporte técnico e da área de relacionamento para entender o sistema e performar ainda mais."
        }
    ]

    const sumCardLeft = [
        {
            title: "Disparo em massa",
            description: "Com o disparo em massa de SMS da Mega Conecta, você pode alcançar um grande público e escalar suas vendas, tudo de forma rápida e fácil."
        },
        {
            title: "Vários tipos de envio",
            description: "Com o SMS da Mega Conecta, você pode programar os envios através de disparo simples, lista simples ou por campanhas personalizadas, garantindo flexibilidade para o tipo de disparo que você precisa."
        },
        {
            title: "Garantia de entrega",
            description: "Para ter resultados positivos, não basta disparar, é preciso que o cliente receba o SMS. Por isso, o SMS da Mega Conecta tem garantia de entrega, potencializando os seus resultados positivos."
        },
        {
            title: "Link WhatsApp",
            description: "Com a adição do link do seu WhatsApp no texto, o cliente poderá responder direto pelo app, facilitando e agilizando o atendimento."
        }
    ]

  return (
    <div className='container-duvidas'>
        <div className='duvidas-content section__padding'>
            <div className='duvidas-content__title-component'>
                <h1 data-aos="fade-left">CONTE COM:</h1>
            </div>
            <div className='duvidas-content__sumario'>
                <div className='sumario-left' data-aos="fade-right">
                    {
                        sumCardLeft.map((sumario, index) => (
                            <SumarioCard
                                key={index}
                                {...sumario}
                            />
                        ))
                    }
                </div>
                <div className='sumario-right' data-aos="fade-left">
                {
                        sumCardRight.map((sumario, index) => (
                            <SumarioCard
                                key={index}
                                {...sumario}
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default DuvidaComponent
