import React from 'react'
import HeaderComponent from '../../../../components/header/HeaderComponent'
import imagem from '../../../../assets/imagem7.png'

const dadosHeader = {
  titulo: 'TELEFONIA VoIP',
  texto: 'Mais que uma linha telefônica, o VoIP da Mega Conecta é economia, tecnologia de ponta e qualidade digital para a comunicação da sua empresa.',
  imagem: imagem
}

const HeaderTelefonia = () => {
  return (
    <div>
      <HeaderComponent
        {...dadosHeader}
      />
    </div>
  )
}

export default HeaderTelefonia
