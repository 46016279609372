import React from 'react'
import HeaderComponent from '../../../../components/header/HeaderComponent'
import imagem from '../../../../assets/imagem6.png'

const dadosHeader = {
  titulo: 'SMS',
  texto: 'Você pode vender muito mais! Alcance clientes em escala e aumente suas conversões com a melhor plataforma de disparo de SMS em massa.',
  imagem: imagem
}

const HeaderSMS = () => {
  return (
    <div>
      <HeaderComponent
        {...dadosHeader}
      />
    </div>
  )
}

export default HeaderSMS
