import React from 'react'
import HeaderComponent from '../../../../components/header/HeaderComponent'
import imagem from '../../../../assets/imagem5.png'

const dadosHeader = {
  titulo: 'URA-BOT',
  texto: 'A solução perfeita para um atendimento autônomo, personalizado e assertivo. A Ura-Bot prospecta, capta e entrega leads qualificados que vão acelerar suas vendas!',
  imagem: imagem
}

const HeaderUra = () => {
  return (
    <div>
      <HeaderComponent
        {...dadosHeader}
      />
    </div>
  )
}

export default HeaderUra
