import React from 'react'
import { HashLink } from 'react-router-hash-link'
import './segmentosComponent.css'

const SegmentosComponent = ({title, text, image}) => {

  return (
    <div className='section-content'>
        <div className='vendasComp-content__left'>
                <div className='left-content'>      
                    <div className='left-content__title'>         
                        <h1 data-aos="fade-down">{title}</h1>
                    </div> 
                        <p data-aos="fade-left">{text}</p>
                    <button data-aos="fade-right"><HashLink to="#contratar">QUERO CONHECER A MEGA CONECTA</HashLink></button>
                </div>
            </div>

            <div className='vendasComp-content__right' data-aos="fade-up">
                <img src={image} alt='Imagem Segmento'/>
            </div>
    </div>
  )
}

export default SegmentosComponent
