import React from 'react'
import './beneficiosCall.css'

import imagem from '../../../../assets/UraBotRobo.png'
import BeneficiosComponent from '../../../../components/beneficiosComponent/BeneficiosComponent'

const conteudoBeneficio = [
    {
        tituloLista: 'AUTONOMIA',
        conteudoLista: 'A Ura-Bot é o sistema perfeito para prospecção e geração de LEADS. De forma autônoma, o sistema disponibiliza LEADS qualificados para sua operação vender mais.'
    },
    {
        tituloLista: 'ALTA PERFORMANCE',
        conteudoLista: 'Seja qual for o tamanho do seu call center, a Ura-Bot entrega clientes realmente interessados em suas ofertas, gerando mais leads e abastecendo o time de vendas.'
    },
    {
        tituloLista: 'ASSERTIVIDADE',
        conteudoLista: 'Com o áudio personalizado e humanizado, a Ura-Bot apresenta a proposta certa, para o cliente certo, gerando muito mais resultado.'
    }
]

const BeneficiosUra = () => {
  return (
    <div className='container-beneficiosCall'>
        <div className='beneficiosCall-content section__padding'>
            <h1>BENEFICIOS</h1>
            <div className='content__sections'>
                <div className='content__section-left'>
                    <ol>
                        {
                            conteudoBeneficio.map((conteudo, index) => (
                                <BeneficiosComponent
                                    key={index}
                                    {...conteudo}/>
                            ))
                        }
                    </ol>
                </div>
                <div className='content__section-right'>
                    <img src={imagem} alt=''/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default BeneficiosUra
