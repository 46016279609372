import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import HeaderTelefonia from '../../containers/solucoesContainer/telefonia/header/HeaderTelefonia'
import ConteTelefonia from '../../containers/solucoesContainer/telefonia/conte/ConteTelefonia'
import BeneficiosTelefonia from '../../containers/solucoesContainer/telefonia/beneficios/BeneficiosTelefonia'
import Vantagens from '../../containers/solucoesContainer/cloudcall/vantagens/Vantagens'
import Conheca from '../../containers/solucoesContainer/cloudcall/conheca/Conheca'
import Revolucionar from '../../containers/solucoesContainer/cloudcall/revolicionar/Revolucionar'
import Footer from '../../containers/homeContainer/footer/Footer'
import Whatsapp from '../../components/whatsapp/Whatsapp'

import { useInView } from "react-intersection-observer";

const TelefoniaVoip = () => {

  const [ref, inView] = useInView({
    /* Optional options */
    triggerOnce: true,
    rootMargin: '0px 0px',
  })

  return (
    <div className='scroll' ref={ref}>
      <Navbar solucoes = {inView ? 'active' : ''}/>
      <HeaderTelefonia/>
      <ConteTelefonia/>
      <BeneficiosTelefonia/>
      <Vantagens/>
      <Conheca/>
      <Revolucionar/>
      <Footer/>
      <Whatsapp/>
    </div>
  )
}

export default TelefoniaVoip
