import React from 'react'
import './sacComp.css'
import imagem from '../../../../assets/sacConectado.png'
import SegmentosComponent from '../../../../components/segmentosComponent/SegmentosComponent'

const segmento = {
    title: 'SAC',
    text: 'Torne o seu atendimento SAC, help-desk ou ouvidoria mais qualificado com as soluções da Mega Conecta!\n Tenha controle total dos índices de atendimentos e aumente a satisfação dos seus clientes!',
    image: imagem
}

const SacComp = () => {
  return (
    <div className='container-vendasComp'>
        <div className='vendasComp-content section__padding'>
           <SegmentosComponent
            {...segmento}/>
        </div>
    </div>
  )
}

export default SacComp
