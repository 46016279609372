import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import HeaderSobre from '../../containers/sobreContainer/header/HeaderSobre'
import Valores from '../../containers/sobreContainer/valores/Valores'
import Chama from '../../containers/sobreContainer/chama/Chama'
import Footer from '../../containers/homeContainer/footer/Footer'
import SobreMaps from '../../containers/sobreContainer/maps/SobreMaps'
import Whatsapp from '../../components/whatsapp/Whatsapp'
import RevolucionarComponent from '../../components/revolucionarComponent/RevolucionarComponent'

import { useInView } from "react-intersection-observer";


const Sobrenos = () => {

  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '0px 0px',
  })

  return (
    <div className='scroll' ref={ref}>
        <Navbar sobre = {inView ? 'active' : ''}/>
        <HeaderSobre/>
        <Valores/>
        <Chama/>
        <SobreMaps/>
        <RevolucionarComponent/>
        <Footer/>
        <Whatsapp/>
    </div>
  )
}

export default Sobrenos
