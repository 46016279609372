import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import Header from '../../containers/homeContainer/body/Header';
import Citacoes from '../../containers/homeContainer/citacoes/Citacoes';
import Contato from '../../containers/homeContainer/contato/Contato';
import Duvidas from '../../containers/homeContainer/duvidas/Duvidas';
import Footer from '../../containers/homeContainer/footer/Footer';
import Negocios from '../../containers/homeContainer/negocios/Negocios';
import Servicos from '../../containers/homeContainer/servicos/Servicos';
import Solution from '../../containers/homeContainer/solution/Solution';
import Whatsapp from '../../components/whatsapp/Whatsapp';

import { useInView } from "react-intersection-observer";

const Homepage = () => {

  const [ref, inView] = useInView({
    /* Optional options */
    triggerOnce: true,
    rootMargin: '0px 0px',
  })

  return (
    <div className='scroll' ref={ref}>
      <Navbar home = {inView ? 'active' : ''}/>
      <Header/>
      <Solution/>
      <Negocios/>
      <Servicos/>
      <Citacoes/>
      <Duvidas/>
      <Contato/>
      <Footer/>
      <Whatsapp/>
    </div>
  )
}

export default Homepage
