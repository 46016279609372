import React, { useEffect, useState } from 'react'
import { BsWhatsapp } from 'react-icons/bs';
import './whatsapp.css'

const Whatsapp = () => {

   const [width, setWidth] = useState(window.innerWidth);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

  return (
    <a href={isMobile ? 'https://wa.me/551141184100' : 'https://web.whatsapp.com/send/?phone=551141184100&text&type=phone_number&app_absent=0'} target="_blank" rel="noopener noreferrer">
        <div className='container-whatsapp' data-aos="fade-left">
            <div className='whatsapp-notification' data-aos="zoom-in">
              <h1>1</h1>
            </div>
            <BsWhatsapp className='whatsapp-image'/>
        </div>
    </a>
  )
}

export default Whatsapp
