import React from 'react'
import './solution.css';
import imagem2 from '../../../assets/imagem2.png'

const Solution = () => {
  return (
    <div className='container-solution section__padding'>
        <div className='solution-content'>
            <div className='solution-content_image'>
                <img src={imagem2} alt='Imagem tela sistema'/>
            </div>
        </div>
    </div>
  )
}

export default Solution
