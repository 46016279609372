import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import HeaderUra from '../../containers/solucoesContainer/ura-bot/header/HeaderUra'
import ConteUra from '../../containers/solucoesContainer/ura-bot/conte/ConteUra'
import BeneficiosUra from '../../containers/solucoesContainer/ura-bot/beneficios/BeneficiosUra'
import Vantagens from '../../containers/solucoesContainer/cloudcall/vantagens/Vantagens'
import Revolucionar from '../../containers/solucoesContainer/cloudcall/revolicionar/Revolucionar'
import Footer from '../../containers/homeContainer/footer/Footer'
import Conheca from '../../containers/solucoesContainer/cloudcall/conheca/Conheca'
import Whatsapp from '../../components/whatsapp/Whatsapp'

import { useInView } from "react-intersection-observer";

const UraBot = () => {

  const [ref, inView] = useInView({
    /* Optional options */
    triggerOnce: true,
    rootMargin: '0px 0px',
  })

  return (
    <div className='scroll' ref={ref}>
      <Navbar solucoes = {inView ? 'active' : ''}/>
      <HeaderUra/>
      <ConteUra/>
      <BeneficiosUra/>
      <Vantagens/>
      <Conheca/>
      <Revolucionar/>
      <Footer/>
      <Whatsapp/>
    </div>
  )
}

export default UraBot
