import React from 'react'
import HeaderComponent from '../../../components/header/HeaderComponent';
import imagem from '../../../assets/imagem1.png';

const dadosHeader = {
  titulo: 'Solução completa para sua empresa',
  texto: 'Com a Mega Conecta você  tem tecnologia inteligente que reduz os custos e multiplica os resultados do seu negócio!',
  imagem: imagem
}

const Header = () => {
  return (
    <>
    <div className='container-header'>
      <HeaderComponent 
        {...dadosHeader}/> 
    </div>
    </>
  )
}

export default Header