import React from 'react'
import './beneficiosCall.css'

import imagem from '../../../../assets/SMSMensagens.png'
import BeneficiosComponent from '../../../../components/beneficiosComponent/BeneficiosComponent'

const conteudoBeneficio = [
    {
        tituloLista: 'RESULTADO',
        conteudoLista: 'Com o disparo de SMS em massa da Mega Conecta, você alcança clientes em escala e aumenta suas conversões com a melhor plataforma de SMS do mercado.'
    },
    {
        tituloLista: 'GARANTIA',
        conteudoLista: 'Com o SMS da Mega Conecta, você tem garantia de entrega! Além de poder acompanhar a taxa de abertura e de conversão dos seus disparos.'
    },
    {
        tituloLista: 'CONTROLE',
        conteudoLista: 'Tenha acesso a relatórios completos, indicadores e métricas que vão te ajudar a melhorar a performance de suas campanhas e gerar muito mais conversões.'
    }
]

const BeneficiosSMS = () => {
  return (
    <div className='container-beneficiosCall'>
        <div className='beneficiosCall-content section__padding'>
            <h1>BENEFICIOS</h1>
            <div className='content__sections'>
                <div className='content__section-left'>
                    <ol>
                        {
                            conteudoBeneficio.map((conteudo, index) => (
                                <BeneficiosComponent
                                    key={index}
                                    {...conteudo}/>
                            ))
                        }
                    </ol>
                </div>
                <div className='content__section-right'>
                    <img src={imagem} alt=''/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default BeneficiosSMS
