import React from 'react'
import HeaderComponent from '../../../../components/header/HeaderComponent'
import imagem from '../../../../assets/imagem8.png'

const dadosHeader = {
  titulo: 'PABX IP',
  texto: 'Saia do analógico! Com o PABX IP da Mega Conecta, a comunicação da sua empresa se torna digital, através de um sistema moderno e de alta tecnologia que vai gerar economia e desenvolvimento para sua empresa',
  imagem: imagem
}

const HeaderPabx = () => {
  return (
    <div>
      <HeaderComponent
        {...dadosHeader}
      />
    </div>
  )
}

export default HeaderPabx
