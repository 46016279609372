import React from 'react'
import './vantagensComponent.css'
import x from '../../../assets/VantagensX.png'
import v from '../../../assets/vantagensV.png'

const VantagensComponent = ({texto, position}) => {
  return (
    <div className={position ? 'section__card reverseFlex': 'section__card' }>
        <div className={position ? 'card-title reverseTitle': 'card-title'}>
            <p>{texto}</p>
        </div>
        <div className={position ? 'card-image reverseImg': 'card-image image' }>
            <img src={position ? v : x} alt='Vantagem'/>
        </div>
    </div>    
  )
}

export default VantagensComponent
