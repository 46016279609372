import React, { useState } from 'react';
import './cookies.css';

import { useCookies } from 'react-cookie';

const CookiesComponent = () => {

    const [cookies, setCookie] = useCookies(["cookies"]);
    const [visible, setVisible] = useState(true);

    const handleClick = (value) => {
        if(value){
            setCookie('cookies', value, { path: '/' });
        }
    }
    
  return (
    <div className={`${visible ? cookies.cookies ? 'none' : 'cookie-container' : 'none' }`}>
        <div className='cookie-content'>
            <h5>Este site usa cookies para melhorar sua experiência. Ao continuar navegando, você concorda com o uso de cookies.</h5>
            <div className='buttons-container'>
                <button className='button-aceitar' onClick={() => handleClick(true)}>Aceitar</button>
                <div className='reject-button' onClick={() => setVisible(false)}>
                    <h5>Rejeitar</h5>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CookiesComponent