import { Route, Routes, HashRouter } from "react-router-dom";
import Homepage from "./pages/homePage/Homepage";
import Sobrenos from "./pages/sobreNos/Sobrenos";
import Cloudcall from "./pages/solucoes/Cloudcall";
import Cloudchat from "./pages/solucoes/Cloudchat";
import UraBot from "./pages/solucoes/UraBot";
import SMS from "./pages/solucoes/SMS";
import TelefoniaVoip from "./pages/solucoes/TelefoniaVoip";
import PabxIp from "./pages/solucoes/PabxIp";
import Vendas from "./pages/segmentos/Vendas";
import SAC from "./pages/segmentos/SAC";
import Atendimento from "./pages/segmentos/Atendimento";
import Cobranca from "./pages/segmentos/Cobranca";

const Routte = () => {

    return(
      <>      
      <HashRouter future={{v7_startTransition: true}}> 
        <Routes>
          <Route exact path="/" caseSensitive={false} element={<Homepage/>}/>      
          <Route path="/sobrenos" caseSensitive={false} element={<Sobrenos/>} />     
          <Route path="/solucoes/cloudcall" caseSensitive={false} element={<Cloudcall/>} /> 
          <Route path="/solucoes/cloudchat" caseSensitive={false} element={<Cloudchat/>} /> 
          <Route path="/solucoes/urabot" caseSensitive={false} element={<UraBot/>} /> 
          <Route path="/solucoes/sms" caseSensitive={false} element={<SMS/>} /> 
          <Route path="/solucoes/telefoniavoip" caseSensitive={false} element={<TelefoniaVoip/>} /> 
          <Route path="/solucoes/pabxip" caseSensitive={false} element={<PabxIp/>} /> 
          <Route path="/segmentos/venda" caseSensitive={false} element={<Vendas/>} /> 
          <Route path="/segmentos/sac" caseSensitive={false} element={<SAC/>} /> 
          <Route path="/segmentos/atendimento" caseSensitive={false} element={<Atendimento/>} /> 
          <Route path="/segmentos/cobranca" caseSensitive={false} element={<Cobranca/>} /> 
        </Routes>
      </HashRouter>
    </>
    );

};

export default Routte;