import React, { useEffect, useState } from 'react'
import './citacoes.css'
import aspas from '../../../assets/aspas.png'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y } from 'swiper';


const frases = [
    {
        frase: 'A Mega foi, de fato, uma virada de chave pra minha empresa. Com a Mega, reduzimos o tempo ocioso dos operadores, além de multiplicarmos as nossas vendas.',
        autor: 'Tatiana Oliveira',
        empresa: 'CEO - Agiliza Soluções'
    },
    {
        frase: 'Não imagino uma operação fazendo sucesso sem utilizar as melhores ferramentas que, com certeza, são as da Mega Conecta.',
        autor: 'Júnior Alves',
        empresa: 'CEO - Turbina Crédito +'
    },
    {
        frase: 'Sempre busquei ferramentas e sistemas que pudessem potencializar as minhas vendas e, com a Mega Conecta, encontrei isso.',
        autor: 'Everton Linhares',
        empresa: 'CEO - Linhares Cred'
    }
]

const Citacoes = () => {

    const [width, setWidth] = useState(window.innerWidth);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

  const isMobile = width <= 768;

  return (
    <div className='container-citacoes gradient__bg-citacoes'>
        <div className='citacoes-content section__padding '>
            <div className='citacoes-content__title'>
                <h1 data-aos="fade-left">AS GRANDES EMPRESAS</h1>
                <h1 data-aos="fade-left">ESCOLHEM A MEGA!</h1>
            </div>  
             <div className='citacoes-content__text-middle'>
                <Swiper
                    modules={[Navigation, Pagination, A11y]}
                    spaceBetween={0}
                    slidesPerView={isMobile ? 1 : 3}
                    navigation
                    pagination={{ clickable: true }}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                >
                    {
                        frases.map((frase, index) => (        
                            <SwiperSlide>                
                                <div className='text-middle__content' key={index}>
                                        <div className='middle__content-image' data-aos="fade-down">
                                            <img src={aspas} alt='Aspas'/>
                                        </div>
                                    <div className='text-middle__content-middle' data-aos="fade-left">
                                            <p>{frase.frase}</p>
                                    </div>
                                    <div className='text-middle__content-end' data-aos="fade-top">
                                        <h2 className='text-middle__h2'>{frase.autor}</h2>
                                        <h2>{frase.empresa}</h2>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
             </div>
        </div>
    </div>
  )
}

export default Citacoes
