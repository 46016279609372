import React from 'react'
import './megaContainerVendas.css'
import imagem1 from '../../assets/megaPessoa.png'
import imagem2 from '../../assets/megaLampada.png'
import imagem3 from '../../assets/megaMulher.png'
import MegaComponent from '../megaComponent/MegaComponent'


import {motion} from 'framer-motion'
import { useRef, useState, useEffect } from 'react'

const megaCard = [
    {
        img: imagem1,
        title: 'MULTI TECNOLOGIA',
        text: 'A Mega Conecta desenvolve soluções inovadoras para transformar a comunicação das empresas e ajudá-las a superar os desafios de seus negócios.'
    },
    {
        img: imagem2,
        title: 'INOVAÇÃO CONSTANTE',
        text: 'Acreditamos que através da tecnologia é possível revolucionar a comunicação empresarial, multiplicar os resultados e aumentar a produtividade e a eficiência.'
    },
    {
        img: imagem3,
        title: 'ATENDIMENTO E SUPORTE',
        text: 'Somos movidos pela paixão de crescer junto com os nossos parceiros e, por isso, nossos clientes são assistidos por especialistas que contribuem para o crescimento constante de suas empresas.'
    }
]

const MegaContainerVendas = () => {

    const carousel = useRef();
    const [width, setWidth] = useState(0);

    useEffect(() => {
      setWidth(carousel.current?.scrollWidth - carousel.current?.offsetWidth)
    }, [])


  return (
    <div className='container-megaContainerVendas'>
        <div className='megaContainerVendas-content section__padding'>
            <div className='megaContainerVendas-content__title'>
                <h1 data-aos="fade-left">COM A MEGA VOCÊ TEM</h1>
            </div>
            <motion.div ref={carousel} className='megaContainerVendas-content__cards' whileTap={{cursor: 'grabbing'}}>
                <motion.div className='cards-container'
                drag="x"
                dragConstraints={{right: 0, left: -width}}
                initial={{x:100}}
                animate={{x:0}}
                transition={{duration: 0.8}}>
                    {megaCard.map((item, index) => (
                        <MegaComponent
                            key={index}
                            {...item}
                        />
                    ))}
                </motion.div>
            </motion.div>
        </div>
    </div>
  )
}

export default MegaContainerVendas
