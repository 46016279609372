import React from 'react'
import './atendimentoComp.css'
import imagem from '../../../../assets/atendimentoOperador.png'
import SegmentosComponent from '../../../../components/segmentosComponent/SegmentosComponent'

const segmento = {
    title: 'ATENDIMENTO',
    text: 'O sucesso do seu negócio anda lado a lado com a qualidade do seu atendimento. Torne o atendimento da sua empresa mais ágil e eficiente com as soluções da Mega Conecta!',
    image: imagem
}

const AtendimentoComp = () => {
  return (
    <div className='container-vendasComp'>
        <div className='vendasComp-content section__padding'>
           <SegmentosComponent
            {...segmento}/>
        </div>
    </div>
  )
}

export default AtendimentoComp
