import React from 'react'
import RevolucionarComponent from '../../../../components/revolucionarComponent/RevolucionarComponent'

const Revolucionar = () => {
  return (
    <div>
      <RevolucionarComponent/>
    </div>
  )
}

export default Revolucionar
