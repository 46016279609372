import React from 'react'
import HeaderCloudChat from '../../containers/solucoesContainer/clouchat/header/HeaderChat'
import Navbar from '../../components/navbar/Navbar'
import ConteChat from '../../containers/solucoesContainer/clouchat/conte/ConteChat'
import BeneficiosChat from '../../containers/solucoesContainer/clouchat/beneficios/BeneficiosChat'
import Vantagens from '../../containers/solucoesContainer/cloudcall/vantagens/Vantagens'
import Revolucionar from '../../containers/solucoesContainer/cloudcall/revolicionar/Revolucionar'
import Footer from '../../containers/homeContainer/footer/Footer'
import Conheca from '../../containers/solucoesContainer/cloudcall/conheca/Conheca'
import Whatsapp from '../../components/whatsapp/Whatsapp'

import { useInView } from "react-intersection-observer";

const Cloudchat = () => {

  const [ref, inView] = useInView({
    /* Optional options */
    triggerOnce: true,
    rootMargin: '0px 0px',
  })

  return (
    <div className='scroll' ref={ref}>
        <Navbar solucoes = {inView ? 'active' : ''}/>
        <HeaderCloudChat/>
        <ConteChat/>
        <BeneficiosChat/>
        <Vantagens/>
        <Conheca/>
        <Revolucionar/>
        <Footer/>
        <Whatsapp/>
    </div>
  )
}

export default Cloudchat
