import React from 'react'
import './servicos.css'
import imagem3 from '../../../assets/imagem3.png'
import imagem4 from '../../../assets/imagem4.png'
import imagem5 from '../../../assets/imagem5.png'
import imagem6 from '../../../assets/imagem6.png'
import imagem7 from '../../../assets/imagem7.png'
import imagem8 from '../../../assets/imagem8.png'
import ServicosCard from '../../../components/servicosCard/ServicosCard'

const serv = [
    {
        title: "CLOUD CALL",
        image: imagem3,
        subtitle: "Alta performance com inteligência!",
        text: "O Cloud Call é o melhor discador automático do mercado e, com ele, você vai ampliar os contatos e as vendas da sua operação",
        link: "/solucoes/cloudcall",
        css: ""
    },
    {
        title: "CLOUD CHAT",
        image: imagem4,
        subtitle: "Descubra o poder do Disparador WhatsApp!",
        text: "Com o Cloud Chat, você tem a solução mais completa e segura para atendimento ativo e receptivo, integrado com WhatsApp Business",
        link: "/solucoes/cloudchat",
        css: "direito"
    },
    {
        title: "URA-BOT",
        image: imagem5,
        subtitle: "O melhor agente virtual do mercado",
        text: "A Ura-Bot é o sistema perfeito para prospecção e geração de LEADS. De forma autônoma, o sistema disponibiliza LEADS qualificados para a sua operação vender mais.",
        link: "/solucoes/urabot",
        css: ""
    },
    {
        title: "SMS",
        image: imagem6,
        subtitle: "Disparos de SMS assertivos que multiplicam resultados",
        text: "Alcance novos clientes e aumente sua taxa de conversão com disparos de SMS em massa, tudo de maneira rápida e fácil.",
        link: "/solucoes/sms",
        css: "direito"
    },
    {
        title: "TELEFONIA VoIP",
        image: imagem7,
        subtitle: "Qualidade digital e economia para sua empresa",
        text: "Com a telefonia VoIP da Mega Conecta, você tem qualidade digital em suas chamadas, além de gerar economia na conta de telefone da sua empresa.",
        link: "/solucoes/telefoniavoip",
        css: ""
    },
    {
        title: "PABX IP",
        image: imagem8,
        subtitle: "Tecnologia inteligente para uma comunicação completa.",
        text: "Saia da era analógica! Com o PABX IP da Mega Conecta, sua empresa tem a solução perfeita para uma comunicação completa e digital.",
        link: "/solucoes/pabxip",
        css: "direito"
    }
]


const Servicos = () => {
  return (
    <div className='container-servicos'>
        <div className='servicos-content section__padding'>
            <div className='servicos-content_title'>
                <p className='servicos-title' data-aos="fade-left">CONHEÇA NOSSAS SOLUÇÕES</p>
            </div>
            {
                serv.map((servicos, index) => (
                    <ServicosCard
                        key={index}
                        {...servicos}
                    />
                ))
            }
        </div>    
    </div>
  )
}

export default Servicos
