import React from 'react'
import imagem from '../../../assets/sobreImagem.png'
import './valores.css'

const Valores = () => {
  return (
    <div className='container-valores'>
        <div className='valores-content section__padding'>
            <div className='valores-content__title'>
                <h1 data-aos="fade-left">NOSSOS VALORES</h1>
            </div>
            <div className='valores-content__section'>
              <div className='valores-content__cards' data-aos="fade-up">
                <div className='card' id="primeiro">
                  <h1>CONEXÃO</h1>
                </div>
                <div className='card'id="segundo">
                  <h1>EMPATIA</h1>
                </div>
                <div className='card' id="terceiro">
                  <h1>INTEGRIDADE</h1>
                </div>
                <div className='card' id="quarto">
                  <h1>LIDERANÇA</h1>
                </div>
              </div>
              <div className='valores-content__imagem' data-aos="fade-left">
                  <img src={imagem} alt='Valores Mega Conecta'/>
              </div>
            </div>
        </div>
    </div>
  )
}

export default Valores
