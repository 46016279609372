import React from 'react'
import SumarioCard from '../../../components/sumarioCard/SumarioCard'
import '../style/duvidaComponent.css'

const DuvidaComponent = () => {
    
    const sumCardRight = [
        {
            title: "Bot identificador de alô",
            description: "Receba chamadas produtivas! Com o Bot identificador de alô, o sistema realiza o reconhecimento da voz humana, validando a chamada, eliminando as caixas postais e entregando ligações assertivas para os seus operadores."
        },
        {
            title: "Dashboard completa e dinâmica",
            description: "Tenha acesso a métricas e indicadores de performance em tempo real para otimizar sua operação e aumentar suas vendas."
        },
        {
            title: "Integração com os melhores CRMs",
            description: "O Cloud Call possui API de integração para você utilizar o sistema junto ao seu CRM."
        },
        {
            title: "Softphone Integrado",
            description: "Tenha mais facilidade e praticidade! Com o Cloud Call, você tem o Softphone integrado ao sistema que é 100% em nuvem, com isso, você não precisa instalar nenhum aplicativo no computador."
        },
        {
            title: "Tabulações personalizadas",
            description: "Crie tabulações personalizadas para mapear e gerenciar os indicadores mais importantes sobre o atendimento do seu time."
        },
        {
            title: "Treinamento e suporte completo",
            description: "Com a Mega Conecta, você tem apoio do time de suporte técnico e da área de relacionamento para facilitar o uso do sistema e performar ainda mais."
        }
    ]

    const sumCardLeft = [
        {
            title: "Qualificador de chamadas",
            description: "O Qualificador identifica os números desqualificados da sua base, dessa forma, ele elimina automaticamente esses números. Assim, o sistema liga somente para os clientes que estão com números qualificados, com isso, você terá agilidade no atendimento, gerando mais rapidez e economia. "
        },
        {
            title: "Integração com SMS",
            description: "Aumente sua taxa de conversão! Com o SMS integrado ao Discador, você potencializa as suas campanhas através de disparos automáticos de SMS para os clientes que não tiveram contato por ligação."
        },
        {
            title: "Sincronização WhatsApp",
            description: "Atendimento multicanal! Com a sincronização do Discador Automático com o WhatsApp, você pode iniciar uma conversa com um simples clique."
        },
        {
            title: "Mapeamento de mailing",
            description: "Crie campanhas assertivas! Com mapeamento de mailing, você pode segmentar suas campanhas por região, Estado e até DDD. Além de poder mapear os locais com maior taxa de conversão e melhorar ainda mais os seus resultados."
        },
        {
            title: "Telefonia Digital ",
            description: "Tenha qualidade máxima em suas chamadas! Com o Cloud Call, você tem telefonia VoIP que garante qualidade de ligação e economia nas suas contas de telefone."
        },
        {
            title: "4 modelos de discadores integrados",
            description: "Power, Preditivo, Preview ou Click To Call. com o Cloud Call, você tem liberdade para escolher o tipo de discador que a sua operação irá utilizar."
        }
        
    ]

  return (
    <div className='container-duvidas'>
        <div className='duvidas-content section__padding'>
            <div className='duvidas-content__title-component'>
                <h1 data-aos="fade-left">CONTE COM:</h1>
            </div>
            <div className='duvidas-content__sumario'>
                <div className='sumario-left' data-aos="fade-right">
                    {
                        sumCardLeft.map((sumario, index) => (
                            <SumarioCard
                                key={index}
                                {...sumario}
                            />
                        ))
                    }
                </div>
                <div className='sumario-right' data-aos="fade-left">
                {
                        sumCardRight.map((sumario, index) => (
                            <SumarioCard
                                key={index}
                                {...sumario}
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default DuvidaComponent
