import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y } from 'swiper';


import './negocios.css'



const cards = [
  {
      titulo: 'Vendas',
      descricao: 'A Mega Conecta tem as soluções certas para você multiplicar suas vendas e turbinar os seus resultados!',
      link: '/segmentos/venda'
  },
  {
    titulo: 'SAC',
    descricao: 'Com as soluções da Mega Conecta, você tem máxima eficiência e qualidade para seu SAC, help-desk ou ouvidoria!',
    link: '/segmentos/sac'
  },
  {
    titulo: 'Atendimento',
    descricao: 'Transforme a comunicação da sua empresa e aumente satisfação dos seus clientes com as soluções inteligentes da Mega Conecta.',
    link: '/segmentos/atendimento'
  },
  {
    titulo: 'Cobrança',
    descricao: 'Multiplique os resultados na sua operação de cobrança com a Mega Conecta! Aumente as chances de negociações e tenha mais eficiência na comunicação com seus clientes.',
    link: '/segmentos/cobranca'
  },
]

const Negocios = () => {

    const [width, setWidth] = useState(window.innerWidth);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  }

  const isMobile = width <= 768;

  return (
    <div className='container-negocios'>
      <div className='negocios-content section__padding'>
        <div className='negocios-content_text'>
            <h1 data-aos="fade-left">PARA TODOS OS NEGÓCIOS,</h1>
            <h1 data-aos="fade-left">UMA ÚNICA SOLUÇÃO!</h1>
            <p data-aos="fade-right">Para atendimento Ativo e Receptivo, a Mega Conecta oferece soluções de alta performance que resolvem os problemas da sua empresa.</p>
        </div>
        <div className='negocios-content_cards-vendas'>
          <Swiper
            modules={[Navigation, Pagination, A11y]}
            spaceBetween={isMobile ? 0 : -25}
            slidesPerView={isMobile ? 1 : 3}
            navigation
            pagination={{ clickable: true }}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {cards.map((card, index) => (
                <SwiperSlide>
                  <div className='cards_content'>
                      <div className='cards-title'>
                            <h2>{card.titulo}</h2>
                        </div>
                        <div className='cards-descricao'>
                            <p>{card.descricao}</p>
                        </div>
                        <div className='cards-link'>
                            <Link onClick={scrollToTop} to={card.link}><h3>Saiba Mais!</h3></Link>
                        </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default Negocios
