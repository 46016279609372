import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import HeaderVendas from '../../containers/segmentosContainer/vendas/header/HeaderVendas'
import VendasComp from '../../containers/segmentosContainer/vendas/vendasComp/VendasComp'
import MegaContainerVendas from '../../components/megaContainer/MegaContainerVendas'
import Revolucionar from '../../containers/solucoesContainer/cloudcall/revolicionar/Revolucionar'
import Footer from '../../containers/homeContainer/footer/Footer'

import '../../components/scrollAll/ScrollAll.css'
import SegmentosCompSolucoes from '../../components/segmentosSolucoes/SegmentosCompSolucoes'
import Whatsapp from '../../components/whatsapp/Whatsapp'

import { useInView } from "react-intersection-observer";

const Vendas = () => {

  const [ref, inView] = useInView({
    /* Optional options */
    triggerOnce: true,
    rootMargin: '0px 0px',
  })

  return (
    <div className='scroll' ref={ref}>
      <Navbar segmentos = {inView ? 'active' : ''}/>
      <HeaderVendas/>
      <VendasComp/>
      <MegaContainerVendas/>
      <SegmentosCompSolucoes/>
      <Revolucionar/>
      <Footer/>
      <Whatsapp/>
    </div>
  )
}

export default Vendas
