import React from 'react'
import './sumariocard.css'

const SumarioCard = ({title, description}) => {


  return (
    <details>
      <summary>
        <span className="summary-title">{title}</span>
          <div className="summary-chevron-up">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
          </div>
      </summary>

      <div className="summary-content">{description}</div>
        <div className="summary-chevron-down">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg> 
        </div>
  </details>
  )
}

export default SumarioCard
