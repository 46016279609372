import React from 'react'
import './chama.css'
import imagem from '../../../assets/sobreChama.png'

const Chama = () => {
  return (
    <div className='container-chama'>
        <div className='chama-content section__padding'>
            <div className='chama-content__section-left' data-aos="fade-up">
                <img src={imagem} alt='Nossa chama'/>
            </div>
            <div className='chama-content__section-right'>
                <h1 data-aos="fade-left">NOSSA CHAMA</h1>
                <p data-aos="fade-right">Somos uma empresa movida pela paixão de
                conectar pessoas</p>
                <p data-aos="fade-right">Nós criamos novas soluções, desenvolvemos
                novas tecnologias e superamos nossos limites
                para você ir além dos seus.
                </p>
                <p data-aos="fade-right">
                Porque criação e inovação, fogo e evolução,
                inspiração e energia, transformação e
                tecnologia, não são apenas palavras, são o
                combustível que alimenta a nossa chama.
                </p>
            </div>
        </div>
    </div>
  )
}

export default Chama
