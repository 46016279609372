import React from 'react'
import './vantagens.css'
import VantagensComponent from '../../../../components/vantagensComponents/vantagemCard/VantagensComponent'

const vantagemLeft = [
  {
    texto: 'Sem Taxa de contratação',
    position: 0
  },
  {
    texto: 'Sem fidelidade',
    position: 0
  },
  {
    texto: 'Sem multa de cancelamento',
    position: 0
  },
  {
    texto: 'Sem burocracia',
    position: 0
  },
]

const vantagemRight = [
  {
    texto: 'Instalação 100% gratuita',
    position: 1
  },
  {
    texto: 'Implantação 100% imediata',
    position: 1
  },
  {
    texto: 'Sistema 100% em nuvem',
    position: 1
  },
  {
    texto: 'Qualidade 100% garantida',
    position: 1
  },
]

const Vantagens = () => {
  return (
    <div className='container-vantagens'>
      <div className='vantagens-content section__padding gradient__bg-citacoes'>
          <div className='vantagens-content__title'>
              <h1 data-aos="fade-left">CONTRATAÇÃO</h1>
          </div>
          <div className='vantagens-content__section'>
            <div className='section-left' data-aos="fade-right">
                    {
                        vantagemLeft.map((vantagem, index) => (
                            <VantagensComponent
                                key={index}
                                {...vantagem}
                            />
                        ))
                    }
            </div>
            <div className='section-right' data-aos="fade-left">
                    {
                        vantagemRight.map((vantagem, index) => (
                            <VantagensComponent
                                key={index}
                                {...vantagem}
                            />
                        ))
                    }
            </div>
          </div>
      </div>  
    </div>
  )
}

export default Vantagens
