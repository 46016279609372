import React from 'react'
import './megaComponent.css'


const MegaComponent = ({img, title, text}) => {
  return (
      <div className='megaCard' data-aos="fade-right">
        <img src={img} alt='Inovações com a Mega Conecta'/>
            <h2>{title}</h2>
            <p>{text}</p>
      </div>
  )
}

export default MegaComponent
