import React from 'react'
import './duvidas.css'
import SumarioCard from '../../../components/sumarioCard/SumarioCard'

const Duvidas = () => {

const sumCardLeft = [
    {
        title: "Existe quantidade mínima de licenças para contratação ?",
        description: "Não! Você pode contratar as soluções da Mega Conecta, independentemente do tamanho e da estrutura da sua empresa."
    },
    {
        title: "Os serviços possuem taxa de instalação ?",
        description: "Não! Na Mega Conecta, a instalação é IMEDIATA e TOTALMENTE GRATUITA."
    },
    {
        title: "Posso integrar com outros sistemas ?",
        description: "Sim! As soluções da Mega Conecta possuem APIs para você integrar com outros sistemas"
    },
    {
        title: "A MEGA CONECTA age dentro da regra do 0303 ?",
        description: "Sim! A Mega Conecta está de acordo com todas as normas e regras que regem o nosso mercado de atuação."
    }
]


const sumCardRight = [
    {
        title: "Os serviços possuem fidelidade ?",
        description: "Não! Com a Mega Conecta, você tem LIBERDADE e, mais do que isso, você tem GARANTIA DE QUALIDADE para continuar conosco enquanto estiver satisfeito."
    },
    {
        title: "O que preciso para utilizar os serviços da Mega Conecta ?",
        description: "Não há um setup padrão, pois, cada modelo de negócio possui uma estrutura própria. Contudo, é comum o uso de computadores; telefone IP; headset e conexão com a internet."
    },
    {
        title: "Quem faz a importação da base no sistema ?",
        description: "Os serviços da Mega Conecta possuem sistemas de gestão que vão te possibilitar inserir sua base de dados de forma fácil, rápida e segura."
    },
    {
        title: "Existe um canal para SUPORTE e ATENDIMENTO AO CLIENTE ?",
        description: "Sim! Com a Mega Conecta você está amparado pelo time de suporte técnico e a área de relacionamento com o cliente."
    }
]

  return (
    <div className='container-duvidas'>
        <div className='duvidas-content section__padding'>
            <div className='duvidas-content__title'>
                <h1 data-aos="fade-left">PRINCIPAIS DÚVIDAS</h1>
                <p data-aos="fade-right">Entenda melhor como funciona a Mega Conecta</p>
            </div>
            <div className='duvidas-content__sumario'>
                <div className='sumario-left'  data-aos="fade-right">
                    {
                        sumCardLeft.map((sumario, index) => (
                            <SumarioCard
                                key={index}
                                {...sumario}
                            />
                        ))
                    }
                </div>
                <div className='sumario-right'  data-aos="fade-left">
                {
                        sumCardRight.map((sumario, index) => (
                            <SumarioCard
                                key={index}
                                {...sumario}
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default Duvidas
