import React from 'react'
import HeaderComponent from '../../../../components/header/HeaderComponent'
import imagem from '../../../../assets/vendasImagem.png'

const dadosHeader = {
  titulo: 'ENTENDEMOS MUITO BEM OS DESAFIOS DO SEU SEGMENTO',
  texto: 'A Mega Conecta desenvolve soluções que atendem as necessidades de acordo com os processos característicos de cada segmento',
  imagem: imagem
}

const HeaderVendas = () => {
  return (
    <div>
      <HeaderComponent
        {...dadosHeader}
      />
    </div>
  )
}

export default HeaderVendas
