import React from 'react'
import SumarioCard from '../../../components/sumarioCard/SumarioCard'
import '../style/duvidaComponent.css'

const DuvidaComponent = () => {
    
    const sumCardRight = [
        {
            title: "Mapeamento de envios",
            description: "Tenha acesso a toda jornada do funil! Do envio da mensagem até a resposta do cliente, você pode acompanhar tudo de maneira fácil, simples e em tempo real."
        },
        {
            title: "Mensagens automáticas",
            description: "Esqueça os planos com limite de mensagens. Com o Cloud Chat você envia e recebe mensagens ilimitadas."
        },
        {
            title: "Métricas e relatórios de performance",
            description: "O Cloud Chat oferece métricas e relatórios para você acompanhar e melhorar a performance da sua equipe."
        },
        {
            title: "Treinamento e suporte completo",
            description: "Com a Mega Conecta, você tem apoio do time de suporte técnico e da área de relacionamento para facilitar o uso do sistema e performar ainda mais."
        }
    ]

    const sumCardLeft = [
        {
            title: "Módulo Disparador",
            description: "Com o Cloud Chat, você pode utilizar o módulo disparador para criar campanhas de prospecção ativa e captação de novos clientes para aumentar as suas vendas."
        },
        {
            title: "Mensagens Ilimitadas",
            description: "Esqueça os planos com limite de mensagens. Com o Cloud Chat você envia e recebe mensagens ilimitadas."
        },
        {
            title: "Disparador com arquivos de mídia integrado",
            description: "Já imaginou poder adicionar imagens, vídeos e documentos nas suas mensagens de prospecção? Com o Cloud Chat você pode!"
        },
        {
            title: "Chatbot de saudação, prospecção e direcionamento",
            description: "Programe mensagens para primeiro contato, prospecção e direcionamento dos clientes captados."
        }
    ]

  return (
    <div className='container-duvidas'>
        <div className='duvidas-content section__padding'>
            <div className='duvidas-content__title-component'>
                <h1 data-aos="fade-left">CONTE COM:</h1>
            </div>
            <div className='duvidas-content__sumario'>
                <div className='sumario-left' data-aos="fade-right">
                    {
                        sumCardLeft.map((sumario, index) => (
                            <SumarioCard
                                key={index}
                                {...sumario}
                            />
                        ))
                    }
                </div>
                <div className='sumario-right' data-aos="fade-left">
                {
                        sumCardRight.map((sumario, index) => (
                            <SumarioCard
                                key={index}
                                {...sumario}
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default DuvidaComponent
