import React from 'react'
import './vendasComp.css'
import imagem from '../../../../assets/vendasFoguete.png'
import SegmentosComponent from '../../../../components/segmentosComponent/SegmentosComponent'

const segmento = {
    title: 'VENDAS',
    text: 'Conte com a Mega Conecta para aumentar o potencial de vendas do seu negócio e potencializar os seus resultados com a eficiências das melhores soluções do mercado!',
    image: imagem
}

const VendasComp = () => {
  return (
    <div className='container-vendasComp'>
        <div className='vendasComp-content section__padding'>
           <SegmentosComponent
            {...segmento}/>
        </div>
    </div>
  )
}

export default VendasComp
