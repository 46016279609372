import './App.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import "aos/dist/aos.css";
import Routte from './Routes';
import Aos from "aos";
import { useEffect } from 'react';

import { Helmet } from 'react-helmet';
import CookiesComponent from './components/cookies/Cookies';

function App() {

  useEffect(() => {
    Aos.init();
  }, [])

  return (
    <div className="App">
      <Helmet>
        <title>Mega Conecta</title>

        <meta property="og:title" content="Mega Conecta" />
        <meta property="og:description" content="Com a Mega Conecta você tem tecnologia inteligente que reduz os custos e multiplica os resultados do seu negócio" />
      
        <meta property="og:title" content="Mega conecta" />
        <meta property="og:description" content="Com a Mega Conecta você tem tecnologia inteligente que reduz os custos e multiplica os resultados do seu negócio" />
        
        <meta property="og:title" content="mega conecta" />
        <meta property="og:description" content="Com a Mega Conecta você tem tecnologia inteligente que reduz os custos e multiplica os resultados do seu negócio" />

        <meta property="og:title" content="MegaConecta" />
        <meta property="og:description" content="Com a Mega Conecta você tem tecnologia inteligente que reduz os custos e multiplica os resultados do seu negócio" />

        <meta property="og:title" content="megaconecta" />
        <meta property="og:description" content="Com a Mega Conecta você tem tecnologia inteligente que reduz os custos e multiplica os resultados do seu negócio" />

        <meta property="og:image" content="%PUBLIC_URL%/LogoSolucoes.png" />
        <meta property="og:image:alt" content="Logo Mega Conecta"/>
        <meta property="og:url" content="https://megaconecta.com.br" />
        <meta property="og:type" content="website" />

        <meta name="robots" content="index, follow"/>

        <meta name="keywords" content="discador, disparador whatsapp, sms, ura, cloudcall"></meta>

        <meta name="twitter:title" content="Mega Conecta" />
        <meta name="twitter:description" content="Com a Mega Conecta você tem tecnologia inteligente que reduz os custos e multiplica os resultados do seu negócio" />
        <meta name="twitter:image" content="%PUBLIC_URL%/LogoSolucoes.png" />
        <meta name="twitter:card" content="%PUBLIC_URL%/LogoSolucoes.png" />
      </Helmet>

      <Routte/>
      <CookiesComponent/>
    </div>
  );
}

export default App;
