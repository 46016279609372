import React from 'react'
import { HashLink } from 'react-router-hash-link'
import './header.css'

const HeaderComponent = ({titulo, texto, imagem}) => {
  return (
    <div className='container-header gradient__bg'>
      <div className='header-content section__padding '>
        <div className='header_content__todo'>
          <div className='header-content__left'>
            <div className='left-section'>
              <h1 data-aos="fade-right">{titulo}</h1> 
              <p data-aos="fade-left">{texto}</p>
              <div className='header-content__button'>
                <HashLink to="#contratar"><button type="button" data-aos="fade-up">Quero Agora!</button></HashLink>
              </div>
            </div>  
          </div>
          <div className='header-content__image'>
            <img src={imagem} alt='Imagem Header' data-aos="zoom-in"/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderComponent
