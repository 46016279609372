import React from 'react'
import DuvidaComponent from '../../../../components/duvidaComponent/cloudcall/DuvidaComponent'

const Conte = () => {

  return (
    <div>
        <DuvidaComponent/>
    </div>
  )
}

export default Conte

