import React, { useEffect, useState } from 'react'
import '../../../../components/conhecaComponent/conhecaComponent.css'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y } from 'swiper';
import { Link } from 'react-router-dom'

const cards = [
  {
      titulo: 'CLOUD CALL',
      descricao: 'O Cloud Call é o melhor discador automático do mercado e, com ele, você vai ampliar os contatos e as vendas da sua operação.',
      link: '/solucoes/cloudcall'
  },
  {
      titulo: 'CLOUD CHAT',
      descricao: 'Com o Cloud Chat, você tem a solução mais completa e segura para atendimento ativo e receptivo, integrado com WhatsApp Business.',
      link: '/solucoes/cloudchat'
  },
  {
      titulo: 'URA-BOT',
      descricao: 'A Ura-Bot é o sistema perfeito para prospecção e geração de LEADS. De forma autônoma, o sistema disponibiliza LEADS qualificados para sua operação vender mais.',
      link: '/solucoes/urabot'
  },
  {
      titulo: 'SMS',
      descricao: 'Aumente suas vendas de forma direta e eficaz com o poder do disparo de SMS assertivos que multiplicam os resultados da sua empresa.',
      link: '/solucoes/sms'
  },
  {
      titulo: 'Telefonia VoIP',
      descricao: 'Com a telefonia VoIP, você economiza e tem chamadas de alta qualidade em qualquer lugar do mundo.',
      link: '/solucoes/telefoniavoip'
  },
  {
      titulo: 'PABX IP',
      descricao: 'Transforme a comunicação da sua empresa com a praticidade, flexibilidade e alta tecnologia do PABX IP.',
      link: '/solucoes/pabxip'
  },
]

const Conheca = () => {

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  }
  
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
  }

  useEffect(() => {
          window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  
  const isMobile = width <= 768;

  return (
    <div className='container-conhecaComponent'>
      <div className='conhecaComponent-content section__padding'>
        <div className='conhecaComponent-content_text'>
            <h1 data-aos="fade-left">CONHEÇA OUTRAS SOLUÇÕES</h1> 
            <p data-aos="fade-right">PARA POTENCIALIZAR E MULTIPLICAR OS RESULTADOS DA SUA EMPRESA!</p>
        </div>
        <div className='conhecaComponent-content_cards-vendas'>
              <Swiper
                modules={[Navigation, Pagination, A11y]}
                spaceBetween={isMobile ? 0 : -25}
                slidesPerView={isMobile ? 1 : 3}
                navigation
                pagination={{ clickable: true }}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                >
              {cards.map((card, index) => (
                <SwiperSlide>   
                  <div className='conhecaoComponent__cards_content' key={index}>
                      <div className='conhecaoComponent__cards-title'>
                          <h2>{card.titulo}</h2>
                      </div>
                      <div className='conhecaoComponent__cards-descricao'>
                          <p>{card.descricao}</p>
                      </div>
                      <div className='conhecaoComponent__cards-link'>
                          <Link onClick={scrollToTop} to={card.link}><h3>Saiba mais!</h3></Link>
                      </div>
                  </div>
                </SwiperSlide>
              ))}
              </Swiper>
        </div>
      </div>
    </div>
  )
}

export default Conheca
