import React from 'react'
import './beneficiosCall.css'

import imagem from '../../../../assets/CloudChatSamrtphone.png'
import BeneficiosComponent from '../../../../components/beneficiosComponent/BeneficiosComponent'

const conteudoBeneficio = [
    {
        tituloLista: 'ECONOMIA',
        conteudoLista: 'Você ainda utiliza telefones diferentes para cada atendente da sua empresa? Com o Cloud Chat você pode centralizar tudo em um único número e um único WhatsApp profissional.'
    },
    {
        tituloLista: 'ALTA PERFORMANCE',
        conteudoLista: 'Torne o atendimento da sua empresa mais ágil e adaptável com um software de atendimento que pode ser implementado de forma rápida e simples'
    },
    {
        tituloLista: 'FLEXIBILIDADE',
        conteudoLista: 'Com o Cloud Chat você tem flexibilidade para o seu time acessar e utilizar a plataforma de onde estiverem.'
    }
]

const BeneficiosChat = () => {
  return (
    <div className='container-beneficiosCall'>
        <div className='beneficiosCall-content section__padding'>
            <h1 data-aos="fade-left">BENEFICIOS</h1>
            <div className='content__sections'>
                <div className='content__section-left'>
                    <ol>
                    {
                        conteudoBeneficio.map((conteudo, index) => (
                            <BeneficiosComponent
                                {...conteudo}/>
                        ))
                    }
                    </ol>
                </div>
                <div className='content__section-right' data-aos="fade-up">
                    <img src={imagem} alt=''/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default BeneficiosChat
