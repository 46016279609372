import React from 'react'
import './segmentosComp.css'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y } from 'swiper';
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

const cards = [
      {
        titulo: 'Vendas',
        descricao: 'A Mega Conecta tem as soluções certas para você multiplicar suas vendas e turbinar os seus resultados!',
        link: '/segmentos/venda'
    },
    {
        titulo: 'SAC',
        descricao: 'Com as soluções da Mega Conecta, você tem máxima eficiência e qualidade para seu SAC, help-desk ou ouvidoria!',
        link: '/segmentos/sac'
    },
    {
        titulo: 'Atendimento',
        descricao: 'Transforme a comunicação da sua empresa e aumente satisfação dos seus clientes com as soluções inteligentes da Mega Conecta.',
        link: '/segmentos/atendimento'
    },
    {
        titulo: 'Cobrança',
        descricao: 'Multiplique os resultados na sua operação de cobrança com a Mega Conecta! Aumente as chances de negociações e tenha mais eficiência na comunicação com seus clientes.',
        link: '/segmentos/cobranca'
    },
  ]
  

const SegmentosCompSolucoes= () => {

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  }

  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
  }

  useEffect(() => {
          window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 768;

  return (
    <div className='container-segmentosComp'>
      <div className='segmentosComp-content section__padding'>
        <div className='segmentosComp-content_text'>
            <h1 data-aos="fade-left">CONHEÇA OUTROS SEGMENTOS</h1> 
            <p data-aos="fade-left">QUE USAM NOSSAS SOLUÇÕES PARA POTENCIALIZAR OS RESULTADOS!</p>
        </div>
        <div className='segmentosComp-content_cards-vendas'>
              <Swiper
                modules={[Navigation, Pagination, A11y]}
                spaceBetween={isMobile ? 0 : -25}
                slidesPerView={isMobile ? 1 : 3}
                navigation
                pagination={{ clickable: true }}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
              >
              {cards.map((card, index) => (
                <SwiperSlide> 
                <div className='segmentosComp__cards_content' key={index}>
                    <div className='segmentosComp__cards-title'>
                        <h2>{card.titulo}</h2>
                    </div>
                    <div className='segmentosComp__cards-descricao'>
                        <p>{card.descricao}</p>
                    </div>
                    <div className='segmentosComp__cards-link'>
                        <Link onClick={scrollToTop} to={card.link}><h3>Saiba Mais!</h3></Link>
                    </div>
                </div>
                </SwiperSlide>
              ))}
              </Swiper>
            </div>
      </div>
    </div>
  )
}

export default SegmentosCompSolucoes
